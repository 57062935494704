export const initialFilterState = () => ({
    startDate: null,
    endDate: null,
    shiftNumber: "",
    cashier: "",
    shiftStatus: "",
    syncProgress: "",    
    page: 1,
    perPage: 10,
  });
  