const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  GetUsers: "ms-profile/user/users",
  GenerateReport: "ms-price-capture/report",
  ShiftRecords: "ms-pos/shift",
  //
  StationPricing: "ms-price-capture/price-capture/admin",
  PriceCapture: "ms-price-capture/",
  GetCompetitors: "ms-price-capture/brand",
  GetStations: "ms-price-capture/station",
  Station: "ms-station/station",
};

export default ApiPath;
