const AccessAuthorization = "/access-authorization";
const ShiftRecords = "/shift-records";
const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  ShiftRecords: ShiftRecords,
};

export default Path;
