export const locale = {
  stocqBOS: "STOCQ Back Office System",
  bos: "Back Office System",
  startShift: "Shift Start Date & Time",
  endShift: "Shift End Date & Time",
  shiftNumber: "Shift Number",
  cashier: "Cashier",
  shiftStatus: "Shift Status",
  syncProgress: "Sync Progress",
  shiftRecords: "Shift Records",
  save: "Save",
  editDetails: "Edit details",
  deactivate: "Deactivate",
  add: "Add",
  sorryNoResultFound: "Sorry, no results found",
  pleaseTryADifferentOne: "Please try a different one.",
  cancel: "Cancel",
  pleaseEnterAValidMobileNumber: "Please enter a valid mobile number.",
  pleaseEnterAValidTelephoneNumber: "Please enter a valid telephone number.",
  yesDeactivateIt: "Yes, Deactivate it",
  yesDeleteIt: "Yes, Delete it",
  yesReactivateIt: "Yes, Reactivate it",
  firstName: "First Name",
  lastName: "Last Name",
  mobileNumber: "Mobile Number",
  all: "All",
  clearAll: "Clear All",
  applyFilters: "Apply Filters",
  name: "Name",
  status: "Status",
  email: "Email",
  filters: "Filters",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember Me",
  logIn: "Log In",
  alreadyExists: "{0} already exists.",
  theEnteredEmailPassIncorrect: "The entered email or password is incorrect",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  amount: "Amount",
  createdDate: "Created Date",
  active: "Active",
  inactive: "Inactive",
  deactivated: "Deactivated",
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  expired: "Expired",
  gasName: "Gas {0}",
  diesel: "Diesel",
  gas91: "Gas 91",
  gas95: "Gas 95",
  gas97: "Gas 97",
  product: "Product",
  ofCharacters: "{0} / {1} Characters",
  view: "View",
  fuel: "Fuel",
  onlyAlphaNumericAllowed: "Only Alphanumeric characters are allowed.",
  onlyAlphaNumericAllowedAndDash:
    "Only Alphanumeric characters and the dash symbol ( - ) are allowed.",
  onlyAlphaNumericAllowedAndSpecial:
    "Only Alphanumeric characters and the following special symbol ( - , . , spaces ) are allowed.",
  mobileNumberIsInvalid: "This mobile number is invalid.",
  driverLicenseIdIsInvalid: "This driver license ID is invalid.",
  date: "Date",
  type: "Type",
  cashInDate: "Cash In Date",
  to: "to",
  gotIt: "Got It",
  onlyNumericAreAllowed: "Only numeric characters are allowed.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  generateFuelCode: "Generate fuel code",
  balance: "Balance",
  productType: "Product Type",
  continue: "Continue",
  searchOrSelect: "Search or select",
  priceHistory: "Price History",
  effectiveDate: "Effective Date",
  fuelPrice: "Fuel Price",
  priceMovement: "Price Movement",
  discount: "Discount",
  dateGenerated: "Date Generated",
  fuelCode: "Fuel Code",
  fuelCodes: "Fuel Codes",
  codeStartDate: "Code Start Date",
  codeEndDate: "Code End Date",
  volumeLimit: "Volume Limit",
  volumeRedeemed: "Volume Redeemed",
  useLimit: "Use Limit",
  useCount: "Use Count",
  scheduled: "Scheduled",
  actions: "Actions",
  liters: "Liters",
  liter: "Liter",
  peso: "Peso",
  totalLitersIsAmount: "Total liters is {0}.",
  totalAmountIs: "Total amount is {0}.",
  minimumOrderIs1Liter: "Minimum order is 1 liter.",
  askConfirmWithPayment: "Proceed with payment?",
  askConfirmWithChanges: "Proceed with changes?",
  nLiter: "{0} Liter",
  nLiters: "{0} Liters",
  ofText: "of {0}",
  productPrice: "Product Price",
  total: "Total",
  totalCaps: "TOTAL",
  pay: "Pay",
  success: "Success",
  exclamatedSuccess: "Success!",
  paymentConfirmed: "Your payment has been confirmed.",
  yourChangesSaved: "Your changes have been saved.",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, got it!",
  literVolume: "Liter Volume",
  welcome: "Welcome,",
  activateAccount: "Activate Account",
  basicInformation: "Basic Information",
  confirmPassword: "Confirm Password",
  setPassword: "Set Password",
  use8CharWithMix: "Use 8 or more characters with a mix of letters, numbers and symbols",
  passwordsDoNotMatch: "Passwords do not match",
  passwordDoesNotMeetRequirements: "Password does not meet requirements",
  goToLogin: "Go to Login",
  pleaseSelectProductType: "Please select product type",
  totalBalance: "Total Balance: {0}",
  insufficientWalletBalance: "Insufficient wallet balance",
  locqPortal: "LOCQ Portal",
  fleetAccounts: "Fleet Accounts",
  stationAccountList: "Station Account List",
  accounts: "Accounts",
  pricing: "Pricing",
  fleetAccountList: "Fleet Account List",
  gasoil: "Gasoil",
  mogas: "Mogas",
  price: "Price",
  fleetPrices: "Fleet Prices",
  priceMovements: "Price Movements",
  viewDetailsOfPriceLocqAppUsers: "View details of PriceLOCQ App users.",
  customerList: "Customer List",
  viewTrackPurchases: "View and track purchases.",
  viewTrackFleetAccounts: "View and track fleet accounts.",
  viewMonitorFleetPrice: "View and monitor price updates.",
  businessNameId: "Business Name/ID",
  stationBusinessName: "Station Business Name",
  nameAndMobileNumber: "Name/Mobile Number",
  natureOfBusiness: "Nature of Business",
  typesOfBusiness: "Type of Business",
  pesoBalance: "Peso Balance​",
  literBalance: "Liter Balance​",
  statusDate: "Status/Date​",
  businessName: "Business Name",
  businessNameNatureOfBusiness: "Business Name, Nature of Business",
  fleetAccountDetails: "Fleet Account Details",
  addFleetAccount: "Add Fleet Account",
  editFleetAccount: "Edit Fleet Account",
  viewDetails: "View Details",
  reactivate: "Reactivate",
  buyFuelId: "Buy Fuel ID",
  cashInId: "Cash In ID",
  cashInIdNumber: "Cash In ID/Number",
  businessInformation: "Business Information",
  businessAddress: "Business Address",
  province: "Province",
  city: "City",
  yes: "Yes",
  no: "No",
  back: "Back",
  reportGenerated: "Report is being generated",
  proceedWithChanges: "Proceed with Changes",
  pumpPrice: "Pump Price",
  update: "Update",
  updateAll: "Update All",
  browseYourFile: "Browse Your Files",
  replace: "Replace",
  pleaseSelectCSVFile: "Please select a .csv file",
  applyUpdates: "Apply Updates",
  dateEdited: "Date Edited",
  priceMovementId: "Price Movement ID",
  deleted: "Deleted",
  downloadSingle: "Download",
  download: "Downloads",
  reportType: "Report Type",
  gasUpStation: "Gas-Up Station",
  pricetracq: "Pricetracq Portal",
  stationPricing: "Station Pricing Dashboard",
  stationPricingSub: "View and track station prices in your area",
  uploadId: "Upload ID",
  brandName: "Brand Name",
  captureDate: "Captured Date",
  dateCaptured: "Date Captured",
  barangay: "Barangay",
  uploaderEmail: "Uploader Email",
  priceCapture: "Price Capture",
  areaGroup: "Area Group",
  noEmail: "No Email",
  mapView: "Map View",
  listView: "List View",
  priceLocqDOesNotHaveAccessLocation: "PriceLOCQ does not have permission to show you location",
  asOfDate: "As of {0}",
  userAccess: "User Access",
  username: "Username",
  adminAccess: "Admin Access",
  appAccess: "App Access",
  portalAccess: "Portal Access",
  monitorUserAccessToPricetracqPortal: "Monitor user access to Pricetracq Portal",
  addUser: "Add User",
  enterEmailAddressHere: "Enter email address here",
  emailAddressExist: "This email address already exists.",
  userCanNowAccessThePriceTracqApp: "User can now access the Pricetracq App",
  goToListOfUsers: "Go to List of Users",
  addAnotherUser: "Add Another User",
  deactivateUser: "Deactivate User?",
  reactivateUser: "Reactivate User?",
  youAreAboutDeactivateUser: "You are about to deactivate {0}'s account.",
  youAreAboutReactivateUser: "You are about to reactivate {0}'s account.",
  thisWillTemporaryPreventUser:
    "This will temporarily prevent {0} from doing any transaction. Do you want to proceed?",
  thisWillEnableUser: "This will enable {0} to do transactions again. Do you want to proceed?",
  userSuccessfullyRegistered: "User successfully registered!",
  youHaveSuccessfullyRegistered: "You have successfully registered {0} to the users' list.",
  registerNewUser: "Register New User",
  competitorList: "Competitor List",
  monitorCompetitorStationsToPricetracqPortal: "View and track competitor stations in your area",
  addNewCompetitorBrand: "Add New Competitor Brand",
  brand: "Brand",
  competitorOf: "Competitor Of",
  fuelCategory: "Fuel Category",
  benchMark: "Benchmark SEAOIL Product",
  createNewCompetitor: "Add New Competitor",
  addProduct: "+ Add Product",
  productName: "Product Name",
  selectFuelCategory: "Select Fuel Category",
  addCompetitor: "Add Competitor",
  youAreAboutToAddCompetitor: "You are about to add {0} to the Competitor List.",
  doYouWanttoProceedQuestion: "Do you want to proceed?",
  addCompetitorQuestion: "Add Competitor?",
  competitorAlreadyExist: "Competitor Already Exist",
  successfullyAddCompetitor: "{0} has been successfully added to the Competitor List.",
  goBackCompetitorList: "Go back to Competitor List",
  addNewCompetitor: "Add New Competitor",
  editCompetitorDetails: "Edit Competitor Details",
  areYouSureYouWantToSaveCompetitor:
    "Are you sure you want to save all changes made to this Competitor?",
  detailsHaveBeenUpdated: "Details have been successfully updated.",
  viewCompetitorDetails: "View Competitor Details",
  lastEdited: "Last edited on ",
  seaoilStationList: "SEAOIL Station List",
  viewAndTrackStationsInYourArea: "View and track stations in your area",
  addNewSeaOilStation: "Add New SEAOIL Station",
  island: "Island",
  longitudeLatitude: "Latitude/Longitude",
  longitude: "Longitude",
  latitude: "Latitude",
  createNewStation: "Add New SEAOIL Station",
  stationCodeZero: "0000",
  location: "Location",
  locationDetails: "Type the location in the input field below",
  stationDetails: "Station Details",
  youAreAboutToAddStation: "You are about to add {0} to the Station List.",
  addNewSeaOilStationQuestion: "Add New SEAOIL Station?",
  areYouSureYouWantToSaveStation:
    "Are you sure you want to save all changes made to this SEAOIL Station?",
  addSeaOilStation: "Add SEAOIL Station",
  seaoilStationDetails: "SEAOIL Station Details",
  goBackStationList: "Go back to Station List",
  noProducts: "No Products",
  doYouWantToProceedQuestion: "Do you want to proceed?",
  successfullyAddStation: "{0} Station has been successfully created.",
  stationAlreadyExist: "Station already exists",
  stationNotFound: "Station not found",
  stationHasNoDepot: "Station has no depot",
  viewAll: "View All",
  competitorStations: "Competitor Stations",
  uploadID: "Upload ID",
  others: "Others",
  NA: "N/A",
  
  dashboard: "Dashboard",
  viewDashboardSubtitle: "View dashboard subtitle here",
  stationName: "Station Name",
  products: "Products",
  export: "Export",
  logout: "Logout",
};

export default Object.freeze(locale);
