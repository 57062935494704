import { Text, Pill, PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { dateFormat, timeFormat } from "utils";
import styles from "./shift-records.module.scss";



export const mapDataToList = ({shifts}) => {
    const {
        startShift,
        endShift,
        shiftNumber,
        cashier,
        shiftStatus,
        syncProgress
      } = shifts;
    return {
        startShift: (
            <>
              <Text className={styles.td}>{dateFormat(startShift)}</Text>
              <Text className={styles.td}>{timeFormat(startShift)}</Text>
            </>
          ),
        endShift: (
            <>
            <Text className={styles.td}>{dateFormat(endShift)}</Text>
            <Text className={styles.td}>{timeFormat(endShift)}</Text>
            </>
        ),
        shiftNumber: (
            <>
              <Text className={styles.td}>{shiftNumber}</Text>
            </>
          ),
        cashier: (
          <>
            <Text className={styles.td}>{'Mary Santos'}</Text>
          </>
        ),
        shiftStatus: (
          <Pill className={styles.status} cheddar={true}>{'ENDED'}</Pill>
        ),
        syncProgress: (
          <div className={styles.sync}>
            <Text className={styles.percentage}>{`100%`} <span className={styles.content}>{`(500/500)`}</span></Text>
          </div>
        ),
        actions: (
            <PopOverMenu options={[]}>
              <MoreVertIcon className={styles.icon} />
            </PopOverMenu>
          ),
    };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};