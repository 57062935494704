import locale from "localization/en";

export const shiftRecordColumns = [
  { key: "startShift", text: locale.startShift, width: "200px" },
  { key: "endShift", text: locale.endShift, width: "200px" },
  { key: "shiftNumber", text: locale.shiftNumber, },
  { key: "cashier", text: locale.cashier, },
  { key: "shiftStatus", text: locale.shiftStatus, },
  { key: "syncProgress", text: locale.syncProgress,  },
  { key: "actions", text: '', width: "20px" },
];
