import React, { useMemo } from "react";
import styles from "./shift-records-filter.module.scss";
import {
  Field,
  Filter,
  DateRange,
} from "components/commons";


const ShiftRecordsFilter = ({
    filterState,
    modifyFilter,
    filterCount,
    applyFilter,
    applyClearFilter,
    applyClearSearch,
    resetFilter,
}) => {
 

  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
            // updateMap();
          }}
          filterCount={filterCount}
        >
          {/* <Field
            className={styles?.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.dateCaptured}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.competitorOf}
          >
            <SelectStations
              {...filterState?.competitorOf}
              isMultiple
              disableAllOption
              allSelectedText={locale.allStations}
              selectAllLabel={locale?.all}
              onChange={(name, { value, label, isAll }) => {
                modifyFilter({
                  competitorOf: {
                    value,
                    label,
                    isAll,
                  },
                });
              }}
            />
          </Field> */}
        </Filter>
      </div>
    </div>
  );
};

export default ShiftRecordsFilter;
