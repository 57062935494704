import React, { useMount, useApi } from "react";
import { Intro } from "components/commons";
import locale from "localization";
import ShiftRecordsFilter from "./shift-records-filter";
import { getShifts } from "apis/shift-records.api";
import { shiftRecordColumns } from "./shift-records-column";
import { initialFilterState } from "./shift-records-filter.state";
import { mapFilterToRequest, mapDataToList } from "./shift-records.mapper";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";


const ShiftRecordsModule = () => {


  const { filter, table } = useDataTable({
    api: {
      api: getShifts,
      params: {
        page: 1,
        perPage: 10,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "shifts",
      mapper: (shifts) =>
        mapDataToList({
          shifts,
        }),
      columns: shiftRecordColumns,
    },
  });

  return (
    <>
      <Intro title={locale.shiftRecords} />
      <ShiftRecordsFilter />
      <DataTableV2 {...table} />
      
    </>
  );
};

export default ShiftRecordsModule;
